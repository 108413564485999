#plusBtnOverride {
  cursor: pointer;
  margin-right: 0;
  margin-left: .5em;
  font-size: .92857143em;
  opacity: .5;
}

#plusBtnOverride:hover {
  opacity: 1;
}
