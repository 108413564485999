#SideBanner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 38.2%;
  height: 100vh;
}


#SideBanner > .bannerBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 20px;

  background-color: #7ac142;
}

#SideBanner .bannerImage {
  background-image: url('../../../assets/images/mov-intro.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  width: 90%;
  height: 100%;

  margin-right: 1.5rem;

  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  right: 0;

}
#SideBanner > .bannerHeader {
  text-align: center;
  z-index: 1;
}

#SideBanner > .bannerSubheader {
  font-weight: 400 !important;
}