textarea {
    font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

.moveCursor {
    cursor: move;
    pointer-events: auto !important;
}

.moveCursorHelper {
    pointer-events: none !important;
}

@media screen and (max-width: 1390px) {
    #removeIconPlacement {
        padding-right: 4.5rem;
    }
}
