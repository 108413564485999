#DashboardPage {
  min-height: 100vh;
  margin: 0;
  border-radius: 0;
  border: none;
}

.tabOpen {
  width: calc(100vw - 150px);
  animation-name: open;
  animation-duration: 0.6s;
  max-width: calc(100% - 150px);
}

.tabClosed {
  width: 100vw;
  animation-name: close;
  animation-duration: 0.6s;
  max-width: 100%;
}

@keyframes close {
  from {width: calc( 100vw - 150px);}
  to {width: 100vw;}
}

@keyframes open {
  from {width: 100vw;}
  to {width: calc( 100vw - 150px);}
}

.backgroundAttributor {
  background-color: #F2F3F8 !important;
  position: relative !important;
  margin-top: 60px !important;
  min-height: calc(100vh - 64px) !important;
}

#Sidebar {
  background-color: #222328;
}

#Sidebar .item {  
  color: #7ac142;
}

#SpecialMenuItem {
  height: 64px;
  width: 100%;
  background-color: #222328;
  padding: 1rem;
}

.logoContainer {
  background-image: url('../../assets/images/newlogo2.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

#MOVOLOGY {
  margin-top: 0.6rem;
  font-weight: 400;
  font-family: Poppins !important;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
}

.activeItem {
  color: white !important;
}

.md-dark {
  color: inherit !important;
}

/* This class is used to override the toast container */
.ui-alerts {
  position: fixed;
  top: 10px;
  right: 10px;
  min-width: 300px;
  z-index: 2;
}

.hamburgerMobileOpen {
  position: relative;
  left: 150px;
  animation-name: open2;
  animation-duration: 0.6s;
}

.hamburgerMobileClose {
  position: relative;
  left: 0px;
  animation-name: close2;
  animation-duration: 0.6s;
}

@keyframes open2 {
  from {left: 0px; }
  to {left: 150px; }
}

@keyframes close2 {
  from {left: 150px; }
  to {left: 0px; }
}