#LoginPage {
  display: flex;
  height: 100vh;
  position: relative;
}

.ui.dimmer .ui.loaderWorkaround.loader:before {
  border-color: rgba(0, 0, 0, 0.2);
}

.ui.dimmer .ui.loaderWorkaround.loader:after {
  border-color: #7ac142 transparent transparent;
}