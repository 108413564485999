#LoginForm {
  display: flex;
  width: 61.8%;
  height: 100vh;
  justify-content: center; 
}

@media only screen and (max-width: 768px){
  #LoginForm {
    width: 100%;
  }
}

#MovologyLogo {
  width: 300px;
  height: 80px;

  background-image: url('../../../assets/images/capture.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

#ShopifyLogo {
  width: 380px;
  height: 100px;

  background-image: url('https://firebasestorage.googleapis.com/v0/b/mov-configs.appspot.com/o/integrationLogos%2Fshopify-logomark-logo.png?alt=media&token=2a76b012-92cf-4d28-a1f5-77975aa73069');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  position: relative;
  left: 50%;
  transform: translateX(-50%);
}