#HamburgerIcon {
  display: inline-block;
  width: 25px;
  margin-top: 5px;
  z-index: 9001;
}

#HamburgerIcon div {
  width: 19px;
  height: 2px;
  background-color: black;
  margin: 4px 0;
  cursor: pointer;
  transition: 0.4s;
}

#HamburgerIcon:hover .left1 {
  -webkit-transform: rotate(-45deg) translate(-3px, 2.7px) scaleX(0.7);
  transform: rotate(-45deg) translate(-3px, 2.2px) scaleX(0.7);
  width: 10px;
}

#HamburgerIcon:hover .left2 {
  -webkit-transform: scaleX(0.7);
  transform: scaleX(0.6);
}

#HamburgerIcon:hover .left3 {
  -webkit-transform: rotate(45deg) translate(-3px, -2.7px) scaleX(0.7);
  transform: rotate(45deg) translate(-3px, -2.2px) scaleX(0.7);
  width: 10px;
}

#HamburgerIcon:hover .right1 {
  -webkit-transform: rotate(45deg) translate(-3px, 2.7px) scaleX(0.7);
  transform: rotate(45deg) translate(6.5px, -2px) scaleX(0.7);
  width: 10px;
}

#HamburgerIcon:hover .right2 {
  -webkit-transform: scaleX(0.7);
  transform: scaleX(0.6);
}

#HamburgerIcon:hover .right3 {
  -webkit-transform: rotate(-45deg) translate(-3px, -2.7px) scaleX(0.7);
  transform: rotate(-45deg) translate(6.5px, 2px) scaleX(0.7);
  width: 10px;
}
