.imageSizeOverride {
  height: 250px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.imageSizeOverride img {
  object-fit: contain !important;
  max-width: 85% !important;
  max-height: 85% !important;
}
