.ui.red.inverted.progress .bar {
  background-color: rgb(234, 53, 70);
}

.ui.orange.inverted.progress .bar {
  background-color: rgb(248, 102, 36);
}

.ui.green.inverted.progress .bar {
  background-color: #7ac142;
}
