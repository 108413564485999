body {
  margin: 0;
  padding: 0;
}

.linkStyling {
  color: #4183c4;
  cursor: pointer;
}

.cursorPointer {
  cursor: pointer;
}

.shadow {
  -webkit-box-shadow: 0px 1px 15px 1px rgba(69, 65, 78, 0.1) !important;
    box-shadow: 0px 1px 15px 1px rgba(69, 65, 78, 0.1) !important;
}

code {
  white-space: pre-wrap;
}

.posRelative {
  position: relative;
}

#TabTitle {
  margin: 10px 0 !important;
  font-weight: 400 !important;
}

#Integration{
  margin: 0 0 0 1rem !important;
  font-weight: 400;
  display: inline-block;
  vertical-align: middle;
}

.textAlignCenter {
  text-align: center;
}

.capitalize {
  text-transform: capitalize !important;
}

.justifyRight {
  display: flex !important;
  justify-content: flex-end !important;
}

.pushable {
  overflow-y: auto !important;
}

.inlineInputButton {
  align-items: center;
  display: flex;
}

.inlineInputButton div:first-child {
  flex-grow: 1;
}

.StripeElement {
  padding: 1rem 0 !important;
}

.ui.dimmer {
  background-color: rgba(0, 0, 0, 0.3);
}

.centerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.centerContainer > div.ui.statistic {
  align-items: center;
}

.ui-alerts {
  z-index: 1001 !important;
}

.showWhiteSpace {
  white-space: pre;
}

.ace_text-input {
  position: absolute !important;
}

.noEmphasisHeader {
  font-weight: 400 !important;
}

.hoverMask {
  position: relative;
}

.hoverMask * {
  position: absolute;
  left: 0;
}

.hoverMask .reveal {
  visibility: hidden;
}

.hoverMask:hover .mask {
  visibility: hidden;
}

.hoverMask:hover .reveal {
  visibility: visible;
}

@media only screen and (max-width: 768px) {
  .sideScrollingTable {
    overflow-x: scroll;
  }
}
.Toastify__toast--success {
  color: rgb(0, 102, 68);
  text-align: center;
  background-color: rgb(111, 179, 150);;
  background-color: rgb(149, 221, 184);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.176) 0px 3px 8px;

}
.Toastify__toast--success .Toastify__close-button {
  color: rgb(0, 102, 68);
}

.Toastify__toast--error {
  text-align: center;
  background-color: rgb(243, 207, 198);
  box-shadow: rgba(0, 0, 0, 0.176) 0px 3px 8px;
  color: rgb(191, 38, 0);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-radius: 4px;

}
.Toastify__toast--error .Toastify__close-button {
  color: rgb(191, 38, 0);
}